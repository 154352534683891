import { WizardModel } from '../../models/WizardModel';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MypageBaseView } from '../base/MypageBaseView';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { CommonAgreeView } from '../common/common_agree/CommonAgreeView';
import { AddMeshWifiSelectView } from '../meshWifi/add_meshWifi_select/AddMeshWifiSelectView';
import { MeshWifiBaseModel } from '../../models/mypage/meshWifi/MeshWifiBaseModel';
import { AddMeshWifiConfirmView } from '../meshWifi/add_meshWifi_confirm/AddMeshWifiConfirmView';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
import { MGeneral } from '../../service/models/entity/MGeneral';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { MCommon } from '../../service/models/entity/primary/MCommon';
import { ContractInfo } from '../Srvnet/revise/mod_net_select/models/ContractInfo';
import { ContractServiceModel } from '../../service/models/apimodels/ContractServiceModel';
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import MypageYakkanConstants from '../../common/MypageYakkanConstants';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';

/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class MeshWifiNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddMeshWifiSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/', params: null, isValid: false, useViewData: false});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: '申込み内容の選択', viewName: this.m_StartViewName, path: 'add_meshWifi_select', params: null, isValid: false, useViewData: false});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '申込み内容の確認', viewName: new AddMeshWifiConfirmView().name, path: 'add_meshWifi_confirm', params: null, isValid: false, useViewData: false});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '契約約款', viewName: new CommonAgreeView().name, path: 'add_meshWifi_agree', params: null, isValid: false, useViewData: false});
        this.m_DefaultWizardFlow.push({stepNum: 4, title: '申込み完了', viewName: new CommonCompleteView().name, path: 'add_meshWifi_complete', params: null, isValid: false, useViewData: false});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.statusCdList = new Array('1', '3');
		req.customerId = mngr.getParams().customerId;
		req.contractFlg = true;
		req.paymentFlg = true;
		req.displayList = new Array();
		req.commonList = new Array();
		// 約款の取得
		if (ObjectUtil.isNotNullOrUndefined(mngr.getViewInfo())) {
			let cacheData: MeshWifiNewWizardModel = mngr.getCache();
			req.yakkanList = new Array();
			req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE_IMPORTANT));
			if(!cacheData.meshWifi.tokotonFlg) {
				req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_SERVICE));
			}
			req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.MESH_WIFI));
			req.yakkanList.push(new MGeneral(MypageCodeTypeConstants.CONTRACT_AGREE, MypageYakkanConstants.ITSCOM_PRICE));
		}
		// 快適メッシュWi-Fi（3台目以降）
		const common1 = new MCommon('快適メッシュWi-Fi（3台目以降）', 'srvother','kaiteki_mesh_wifi','multiple');
		req.commonList.push(common1);
		// 快適メッシュWi-Fi（標準付帯）
		const common2 = new MCommon('快適メッシュWi-Fi（標準付帯）', 'srvother','kaiteki_mesh_wifi','normal');
		req.commonList.push(common2);
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.MESH_WIFI));
		req.displayList.push(new MDisplay(MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, null));
		// サービスマスタ取得
		req.masterFlg = true;
		const target = new ContractServiceModel();
		target.serviceCd = MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME;
		req.serviceKey = new Array();
		req.serviceKey.push(target);
		//対象のサービスコード取得 
		if(!req.generalList) 
			req.generalList = new Array();
		const general = new MGeneral(MypageCodeTypeConstants.MESHWIFI_SERVICE);
		const general2 = new MGeneral(MypageCodeTypeConstants.MESHWIFI_AMOUNT);
		req.generalList.push(general)
		req.generalList.push(general2)
		// 有料サービス（料金シミュでは0円）
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.PAID_SERVICE, null));
		return req;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel(mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest{
		let cacheData: MeshWifiNewWizardModel = mngr.getCache();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'メッシュWi-Fi_申込み';
		});
		const req: RegistInfoRequest = new RegistInfoRequest();		
		// お客様メール
		req.customerMailId = mailconstants.customerMail;
		req.customerId = mngr.getParams().customerId;
		// 業務部門
		req.systemMailId = mailconstants.systemMail;
		// 問い合わせ
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.content = 'メッシュWi-Fi追加申し込み';
		req.systemInfo.establishment = cacheData.meshWifi.establishment;
		if (cacheData.meshWifi.tokotonFlg) {
			req.systemInfo.tokotonApp = '申込なし';
		} else {
			req.systemInfo.tokotonApp = '申込あり';
		}
		if (cacheData.meshWifi.constractionFlg) {
			req.systemInfo.constractionFlg = true;
			req.systemInfo.desiredDate1 = cacheData.meshWifi.desiredDate1.replace(/-/g, '/');
			req.systemInfo.desiredDate2 = cacheData.meshWifi.desiredDate2.replace(/-/g, '/');
			req.systemInfo.desiredDate3 = cacheData.meshWifi.desiredDate3.replace(/-/g, '/');
			req.systemInfo.selectTime1 = view.getValueMap('時間帯').getValueFormKey(cacheData.meshWifi.selectTime1);
			req.systemInfo.selectTime2 = view.getValueMap('時間帯').getValueFormKey(cacheData.meshWifi.selectTime2);
			req.systemInfo.selectTime3 = view.getValueMap('時間帯').getValueFormKey(cacheData.meshWifi.selectTime3);
			req.systemInfo.constructionCost = Number(cacheData.meshWifi.countructionCost).toLocaleString() + '円';
			req.systemInfo.beforeContact = cacheData.meshWifi.beforeContact;
			req.systemInfo.sameDayContact = cacheData.meshWifi.sameDayContact;
			req.systemInfo.witness = cacheData.meshWifi.name;
		} else {
			if (cacheData.meshWifi.countructionCost === 'お見積り')
				req.systemInfo.constructionCost = cacheData.meshWifi.countructionCost;
			else
				req.systemInfo.constructionCost = Number(cacheData.meshWifi.countructionCost).toLocaleString() + '円';
		}
		if (cacheData.meshWifi.wifiFlg)
			req.systemInfo.wireModem = '有';
		else
			req.systemInfo.wireModem = '無';
		return req;
	}
	
	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData(mngr: MypageWizardMngr): MeshWifiNewWizardModel {
		let cacheData = new MeshWifiNewWizardModel();
		cacheData.meshWifi = new MeshWifiBaseModel();
		cacheData.m_ContractInfo = new ContractInfo();
		return cacheData;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak(mngr: MypageWizardMngr): string {
		let test: boolean = false;
		if (test) {
			return '/error';
		}
		return null;
	}
}
/**
 * 画面ウィザードAPI用モデル
 */
export class MeshWifiNewWizardModel {
	// public 変数	-----------------------------------------------------------
		public meshWifi: MeshWifiBaseModel = null;
		public m_ContractInfo: ContractInfo = null;
		public m_ServiceModel	: MDisplay					= null;			// サービス詳細モデル
	// ------------------------------------------------------------------------
}
