import { TrendingUpTwoTone } from '@material-ui/icons';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { DateUtil } from '../../../common/util/DateUtil';
import { MeshWifiBaseModel } from '../../../models/mypage/meshWifi/MeshWifiBaseModel';
import { AddServiceModel } from '../../../service/models/apimodels/AddServiceModel';
import { ViewInfoModel } from '../../../service/models/apimodels/ViewInfoModel';
import { GetSimulationRequest } from '../../../service/models/dto/mypagerenewal/GetSimulationRequest';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ServiceModel } from '../../../service/models/apimodels/ServiceModel';
import { GetMGeneralRequest } from '../../../service/models/dto/mypagerenewal/GetMGeneralRequest';
import { Contract } from '../../../models/bss/contract/Contract';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import MypageCodeTypeConstants from '../../../common/MypageCodeTypeConstants';
import { GetConstructionCostRequest } from '../../../service/models/dto/mypagerenewal/GetConstructionCostRequest';

/**
 * メッシュWi-Fi 追加申込み内容の確認
 */
export class AddMeshWifiConfirmView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddMeshWifiConfirmView'); }
	// --------------------------------------------------------------------------
	
	// private変数  -------------------------------------------------------------
	private m_ServiceList: ServiceModel[] 				= null;	// サービスリスト
	private m_StartDate: Date							= null; //契約日
	private m_CancelDate: Date							= null; //解約日
	private m_CancelStartDate: Date							= null; //解約済み契約の契約開始日
	private m_ServiceCd: string							= null; //サービスコード
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprModel('MeshWifiBaseModel')
	private m_MeshWifiBaseModel: MeshWifiBaseModel	= null;	// メッシュWifi
	// --------------------------------------------------------------------------
	
	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('establishment', '設置方法詳細', 'MeshWifiBaseModel');
		this.addConfig('curFee', '旧月額', 'MeshWifiBaseModel', { converter: '3桁カンマ' });
		this.addConfig('newFee', '新月額', 'MeshWifiBaseModel', { converter: '3桁カンマ' });
		this.addConfig('courseFee', '申込みコースの月額', 'MeshWifiBaseModel', { converter: '3桁カンマ' });
		this.addConfig('countructionCost', '工事費', 'MeshWifiBaseModel', { converter: '3桁カンマ' });
		this.addConfig('amount', '月額利用料', 'MeshWifiBaseModel', { converter: '3桁カンマ' });
		this.addConfig('desiredDate1', '第一希望', 'MeshWifiBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime1', '第一希望　時間帯', 'MeshWifiBaseModel', { disabled: true});
		this.addConfig('desiredDate2', '第二希望', 'MeshWifiBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime2', '第二希望　時間帯', 'MeshWifiBaseModel', { disabled: true});
		this.addConfig('desiredDate3', '第三希望', 'MeshWifiBaseModel', { converter: '日付（yyyy/MM/dd）' });
		this.addConfig('selectTime3', '第三希望　時間帯', 'MeshWifiBaseModel', { disabled: true});
		this.addConfig('beforeContact', '前日連絡先', 'MeshWifiBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('sameDayContact', '当日連絡先', 'MeshWifiBaseModel', { maxLength: 11, validation: '整数' });
		this.addConfig('name', '立ち合い者名', 'MeshWifiBaseModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_MeshWifiBaseModel = this.m_MypageWizardMngr.getCache().meshWifi;
			if (this.m_MeshWifiBaseModel.check1 === true) {
				this.m_MeshWifiBaseModel.establishment = 'お客さまご自身での設置';
			} else {
				this.m_MeshWifiBaseModel.establishment = '訪問での設置';
			}
			const viewInfo: ViewInfoModel = this.m_MypageWizardMngr.getViewInfo();
			// とこサポ未加入の場合表示
			if (!this.m_MeshWifiBaseModel.tokotonFlg) {
				this.setViewMode('とことんサポート', '表示');
				this.m_ServiceList = this.m_MypageWizardMngr.getServiceList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME);
				this.m_MeshWifiBaseModel.amount = this.m_MypageWizardMngr.getTaxFee(this, String(this.m_ServiceList[0].service.payment[0].amount));
			}
			this.paymentSimulation(viewInfo);	// 料金シミュレーション
			// 希望日時
			if (this.m_MeshWifiBaseModel.constractionFlg) {
				this.setViewMode('工事エリア','表示');
			}
			this.refreshView();
		});
	}

	/**
	 * 料金シミュレーション
	 */
	private paymentSimulation(viewInfo: ViewInfoModel): void{
		const req: GetSimulationRequest = new GetSimulationRequest();
		req.customerId = this.m_MypageWizardMngr.getParams().customerId;
		const contractList = this.m_MypageWizardMngr.getViewInfo().contractModel.contractList;
		contractList.sort(
			(firstObject: Contract, secondObject: Contract) =>
			(firstObject.cancelDate> secondObject.cancelDate) ? 1 : -1
		);
		const feeModel = new AddServiceModel;
		const today: Date =DateUtil.getToday();
		const useList : Contract[] = new Array;
		const cancelList : Contract[] = new Array;
		// NETサービス取得リスト(106)
		const netServiceList = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.MESHWIFI_SERVICE, '');
		// 月額利用料取得リスト(152)
		const amountList = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.MESHWIFI_AMOUNT, '');
		for (const meshCd of netServiceList) {
			for (const contract of contractList){
				// NETサービス契約判定
				if (meshCd.generalCd === contract.serviceCD) {
					if (contract.statusCD === '1') {
						useList.push(contract);
					} else if(contract.statusCD === '3') {
						cancelList.push(contract);
					}
				}
			}
		}
		for (const meshAmount of amountList) {
			// メッシュWi-Fi契約中判定
			if (!meshAmount.flag2) {
				for (const contract of contractList) {
					if (meshAmount.generalCd === contract.serviceCD && contract.statusCD === '1') {
						this.m_ServiceCd = meshAmount.generalCd;
					}
				}
			}
			// 申込みコースの月額判定
			// メッシュWi-Fi契約済み
			if (ObjectUtil.isNotNullOrEmptyStr(this.m_ServiceCd)) {
				if (!meshAmount.flag1 && !meshAmount.flag2) {
					this.m_MeshWifiBaseModel.courseFee = String(meshAmount.num1);
					feeModel.serviceCd = meshAmount.generalCd;
				}
			// メッシュWi-Fi1台目
			} else {
				if (meshAmount.flag1) {
					this.m_MeshWifiBaseModel.courseFee = String(meshAmount.num1);
					feeModel.serviceCd = meshAmount.generalCd;
				}
			}
		}
		let particularDate: string = DateUtil.getMonthStart(DateUtil.getBefDay(today, 5));
		let usingFlg = false;
		let oldStartDate: Date = new Date;
		// 利用中が７カ月超えているか
		for(const use1 of useList) {
			this.m_StartDate = new Date(DateUtil.convertToDateWithSlash(use1.startDate))
			// 契約日がparticularDateよりも前か
			if (DateUtil.isAfter(this.m_StartDate, DateUtil.convertToDateFromStr(particularDate))) {
				usingFlg = true;
				break;
			} else {
				oldStartDate = this.m_StartDate;
			}
		}
		// 解約したものと期間を合わせて７カ月を超えるか
		if (!usingFlg) {
			for (const cancel of cancelList) {
				this.m_CancelDate = new Date(DateUtil.convertToDateWithSlash(cancel.cancelDate))
				this.m_CancelStartDate = new Date(DateUtil.convertToDateWithSlash(cancel.startDate))							
				// isAfterが当日を含まないので2日加算
				let cancelDatePlus: Date = DateUtil.getCulcDate(this.m_CancelDate , 2);
				if (DateUtil.isAfter(oldStartDate, cancelDatePlus)) {
					if(DateUtil.isAfter(this.m_CancelStartDate,	DateUtil.convertToDateFromStr(particularDate))) {
						usingFlg = true;
						break;
					} else {
						oldStartDate = this.m_CancelStartDate;
					}
				}
			}
		}
		// 工事費・機器発送費
		const constReq: GetConstructionCostRequest = new GetConstructionCostRequest();
		constReq.constructionCategoryCd = '03';	// 工事カテゴリーコード
		// お客様ご自身での設置を希望するを選択
		if (this.m_MeshWifiBaseModel.check1) {
			constReq.applicationDetailsCd = '07';	// 申込み内容コード
			constReq.temporaryCostCategoryCd = '02';	// 一時金カテゴリーコード
			// メッシュWi-Fi契約数が1以上（3台目以降の希望）
			if (ObjectUtil.isNotNullOrEmptyStr(this.m_ServiceCd)) {
				constReq.multipleFlg = true;	// 二台目フラグ
				constReq.freeFlg = false;	// 無料フラグ
			}
			// 未契約
			else {
				// NET契約7ヶ月以上
				if (usingFlg) {
					constReq.multipleFlg = false;	// 二台目フラグ
					constReq.freeFlg = true;	// 無料フラグ
				} else {
					constReq.multipleFlg = false;	// 二台目フラグ
					constReq.freeFlg = false;	// 無料フラグ
				}
			}
		}
		// 訪問での設置を希望するを選択
		else {
			constReq.applicationDetailsCd = '02';	// 申込み内容コード
			constReq.temporaryCostCategoryCd = '01';	// 一時金カテゴリーコード
			// 契約数が1以上（3台目以降の希望）
			if (ObjectUtil.isNotNullOrEmptyStr(this.m_ServiceCd)) {
				constReq.multipleFlg = true;	// 二台目フラグ
				constReq.freeFlg = false;	// 無料フラグ
			} 
			// 未契約
			else {
				// NET契約7ヶ月以上
				if (usingFlg) {
					constReq.multipleFlg = false;	// 二台目フラグ
					constReq.freeFlg = true;	// 無料フラグ
				} else {
					constReq.multipleFlg = false;	// 二台目フラグ
					constReq.freeFlg = false;	// 無料フラグ
				}
			}
		}
		// 工事費・機器発送費取得
		this.m_MypageWizardMngr.getConstructionCost(this, constReq, null, false, false, result => {
			if (result) {
				if (result.temporaryCost.length > 0) {
					this.setViewMode('工事費単位', '表示');
					if (!constReq.freeFlg && !constReq.multipleFlg) {
						for (const cost of result.temporaryCost) {
							if (!cost.freeFlg && !cost.multipleFlg)
								this.m_MeshWifiBaseModel.countructionCost = this.m_MypageWizardMngr.getTaxFee(this, cost.temporaryCost);
						}
					}	
					else
						this.m_MeshWifiBaseModel.countructionCost = this.m_MypageWizardMngr.getTaxFee(this, result.temporaryCost[0].temporaryCost);
				}
				else 
					this.m_MeshWifiBaseModel.countructionCost = 'お見積り';
				this.refreshView();
			}
		});
		req.addServiceList = new Array();
		feeModel.rentalFlg = '1';
		feeModel.optionCdList = new Array();
		if (!this.m_MeshWifiBaseModel.tokotonFlg)
			feeModel.optionCdList.push(this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, '')[0].namecd)
		req.addServiceList.push(feeModel);
		this.m_CommonService.getPaymentSimulation(this, req, (result) => {
			this.m_MeshWifiBaseModel.curFee = result.breforeFee;
			this.m_MeshWifiBaseModel.newFee = result.afterFee;
			const payment = this.m_MypageWizardMngr.getViewInfo().customerPayment;
			if (this.m_MeshWifiBaseModel.curFee === '0' && payment && payment[0].payment[0].paymentTypeCD === '2')
				this.setViewMode('請求書有料発行', '表示');
			this.refreshView();
		});
	}
	
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 申込む         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MeshWifiBaseModel.confirmCheck = false;
		this.m_MeshWifiBaseModel.tokosupConfirmCheck = false;
		this.refreshModel();
		this.m_MypageWizardMngr.goBack(this);
			
	}

	// --------------------------------------------------------------------------

}
