import { Customer } from '../../../models/bss/customer/Customer';
import { Customer as PaymentCustomer } from '../../../models/bss/customer/payment/Customer';
import { MDisplayInfoModel } from './MDisplayInfoModel';
import { MGeneralInfoModel } from './MGeneralInfoModel';
import { ServiceModel } from './ServiceModel';
import { Contract as OwnerContract } from '../../../models/bss/customer/Contract';
import { Contract } from '../../../models/bss/contract/Contract';
import { MGeneral } from '../entity/MGeneral';
import { MCommon } from '../entity/primary/MCommon';
import { ContractPlanModel } from './ContractPlanModel';
import { MCommonModel } from './MCommonModel';
import { Building } from '../../../models/bss/building/Building';
import { Building as SearchBuilding } from '../../../models/bss/seachBuilding/Building';
import { MFirstJudg } from '../entity/secondary/MFirstJudg';

/**
 * 画面表示用モデル
 */
export class ViewInfoModel {
	// public 変数	-----------------------------------------------------------
	public customer: Customer;							// 
	public yakkanList: MGeneral[];
	public contractModel: ContractPlanModel;					// 
	public displayModelList: MDisplayInfoModel[];		// 
	public generalModelList: MGeneralInfoModel[];		// 
	public commonList: MCommonModel[];		// 
	public serviceList: Map<string,ServiceModel[]>;	// 
	public customerPayment: PaymentCustomer[];		// 
	public ownerContractList: OwnerContract[];		// 
	public buildingList: Building[];		// 
	public searchBuildingList: SearchBuilding[];		// 
	public serviceLineList: MFirstJudg[];
	public inAreaFlg: boolean;
	public inNhkAreaFlg: boolean;
	// ------------------------------------------------------------------------
}
