import { WizardModel } from '../../models/WizardModel';
import { AddNhkSelectView } from '../Srvother/add_nhk_select/AddNhkSelectView';
import { MypageWizardFlow } from './MypageWizardFlow';
import { MypageWizardMngr } from './models/MypageWizardMngr';
import { AddNhkConfirmView } from '../Srvother/add_nhk_confirm/AddNhkConfirmView';
import { CommonCompleteView } from '../common/common_complete/CommonCompleteView';
import { GetViewInfoRequest } from '../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MGeneral } from '../../service/models/entity/MGeneral';
import { MDisplay } from '../../service/models/entity/secondary/MDisplay';
import { CompleteModel } from '../common/common_complete/models/CompleteModel';
import { MypageBaseView } from '../base/MypageBaseView';
import { NhkModel } from '../../service/models/apimodels/NhkModel';
import { Nhk } from '../../models/bss/nhk/Nhk';
import MypageDispdivcdConstants from '../../common/MypageDispdivcdConstants';
import { RegistInfoRequest } from '../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { InquiryMailInfo } from '../../service/models/apimodels/InquiryMailInfo';
import { MAIL_CONSTANTS } from '../../common/MyPageMailIdConstants';
import MypageCodeTypeConstants from '../../common/MypageCodeTypeConstants';
/**
 * ITSCOM HOME_追加ウィザードクラス
 */
export class NhkNewWizardFlow extends MypageWizardFlow {
	// private 変数  ------------------------------------------------------------
	private m_DefaultWizardFlow: WizardModel[]			= null;		// 画面ウィザードリスト
	private m_StartViewName: string						= null;
	// --------------------------------------------------------------------------

	// コンストラクタ  -----------------------------------------------------------
	public constructor() {
		super();
		this.m_StartViewName = new AddNhkSelectView().name;

		// 遷移パターン１
		this.m_DefaultWizardFlow = new Array();
		this.m_DefaultWizardFlow.push({stepNum: 0, title: '契約選択', viewName: null, path: this.getOldUrl() + 'Srvlist/Continfo_list/', params: null, isValid: false, useViewData: false});
		this.m_DefaultWizardFlow.push({stepNum: 1, title: 'コース選択', viewName: this.m_StartViewName, path: 'add_nhk_select', params: null, isValid: false, useViewData: false});
		this.m_DefaultWizardFlow.push({stepNum: 2, title: '申込み内容の確認', viewName: new AddNhkConfirmView().name, path: 'add_nhk_confirm', params: null, isValid: false, useViewData: false});
		this.m_DefaultWizardFlow.push({stepNum: 3, title: '変更申込み完了', viewName: new CommonCompleteView().name, path: 'add_nhk_complete', params: null, isValid: false, useViewData: false});
	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * 画面ウィザードフロー名取得
	 */
	public getWizardFlowName(viewName: string): string {
		if (viewName === this.m_StartViewName)
			return this.constructor.name;
		return null;
	}
	
	/**
	 * 画面ウィザードリスト取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardFlow(mngr: MypageWizardMngr): WizardModel[] {
		return this.m_DefaultWizardFlow;
	}
	
	/**
	 * 画面ウィザードAPI用モデル取得：契約状態で切り替えたり、選択肢で切り替えたり
	 */
	public getWizardApiModel(mngr: MypageWizardMngr, view: MypageBaseView): GetViewInfoRequest {
		const req = new GetViewInfoRequest();
		req.customerId = mngr.getParams().customerId;
		req.paymentFlg = true;
		req.buildingFlg = true;
		req.nhkAreaFlg = true;	// NHK申込みエリアフラグ
		req.contractFlg = true;
		req.displayList = new Array();
		for(let i=0; i<4; i++){
			req.displayList.push(new MDisplay(MypageDispdivcdConstants.NHK_DISCOUNTTYPE,String(i)));
		}
		// 有料サービス（料金シミュでは0円）
		req.generalList = new Array();
		req.generalList.push(new MGeneral(MypageCodeTypeConstants.PAID_SERVICE, null));
		return req;
	}

	/**
	 * 画面ウィザード完了APIモデル取得
	 */
	public getRegistApiModel (mngr: MypageWizardMngr, view: MypageBaseView): RegistInfoRequest {
		const req: RegistInfoRequest = new RegistInfoRequest();
		let cacheData: NhkNewWizardModel = mngr.getCache();
		const mailconstants = MAIL_CONSTANTS.find (mail => {
			return mail.view === 'NHK_申込み';
		});
		req.customerId = mngr.getParams().customerId;
		req.customerMailId = mailconstants.customerMail;
		// req.systemMailId = mailconstants.systemMail;
		req.inquiryId = mailconstants.inquiryMail;
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.discount = cacheData.discountInfo.discountSelect;
		return req;
	}
	
	/**
	 * 画面キャッシュの初期化データ取得
	 */
	public getInitCacheData (mngr: MypageWizardMngr): NhkNewWizardModel {
		let cacheData = new NhkNewWizardModel();
		cacheData.discountInfo = new Nhk();
		return cacheData;
	}

	/**
	 * 画面ウィザードフローの中断判定：例：申し込みが完了していれば、完了済みの画面へ遷移
	 */
	public checkFlowBreak (mngr: MypageWizardMngr, view: MypageBaseView): string {
		const viewCode = '005001';
		// エリア外の場合
		if (view.m_Name === 'AddNhkSelectView' && !mngr.getViewInfo().inNhkAreaFlg) {
			return '/customer_support?no=009' + viewCode;
		}
	}
}
/**
 * 画面ウィザードAPI用モデル
 */
export class NhkNewWizardModel {
	// public 変数	-----------------------------------------------------------
		public discountInfo: Nhk = null;
	// ------------------------------------------------------------------------
}
